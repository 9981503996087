
import Vue from "vue";
import store from "@/store";

export default Vue.extend({
  name: "Billing",

  props: {
    user: {
      type: Object,
      required: true
    },
    isOwner: {
      type: Boolean,
      default: false,
      required: false
    },
    profileId: {
      type: Number,
      required: false
    }
  },

  data: () => ({
    credentials: store.getters["authentication/credentials"],
    history: [] as Array<any>,
    plans: [] as Array<any>,
    download: true as boolean,
    cards: null as any,
    stripe: {} as any,
    brands: {
      visa: {
        img: require("@/assets/images/brands/visa.png"),
        color: "warning"
      },
      mastercard: {
        img: require("@/assets/images/brands/mastercard.png"),
        color: "info"
      }
    },
    changeCard: false as boolean,
    cardElement: null as any,
    cardHolder: "" as string,
    useAsDefault: false as boolean,
    loadings: {
      loadingHistory: true as boolean,
      loadingPlans: true as boolean,
      loadingCards: true as boolean,
      loadingCardSetup: false as boolean,
      loadingAddCard: false as boolean
    } as any
  }),

  computed: {
    sortedCard(): Array<any> {
      if (this.cards?.length) {
        return this.cards;
      }

      return [];
    },
    isProfileOwner(): boolean {
      const id = Number(this.$route.params.id);

      if (id) {
        return this.credentials?.user?.id === id;
      }

      return true;
    }
  },

  async mounted() {
    await this.loadPlans();
    await this.loadPaymentHistory();
    await this.loadCardInfo();
  },

  methods: {
    cardColor(item: any) {
      return (this.brands as any)[item.brand]?.color || "warning";
    },
    async loadPlans() {
      this.loadings.loadingPlans = true;
      try {
        this.plans = await this.$API.billing().getPlans();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loadings.loadingPlans = false;
    },
    async loadCardInfo() {
      try {
        this.cards = await this.$API
          .billing()
          .getAllCards(this.credentials.user.id);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loadings.loadingCards = false;
    },
    async loadStripeInfo() {
      try {
        this.stripe = await this.$API
          .billing()
          .getStripeInfo(this.credentials.user.id);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async loadPaymentHistory() {
      this.loadings.loadingHistory = true;
      try {
        const response = await this.$API.billing().plansHistory(this.user.id);

        this.history = response.map((item: any) => ({
          ...item,
          _expanded: false
        }));

        const [firstItem] = this.history;

        if (firstItem) {
          firstItem._expanded = true;
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loadings.loadingHistory = false;
    },
    async changePlan(plan: string) {
      try {
        await this.$API.billing().changePlans({
          plan
        });
        await this.loadPaymentHistory();
        this.user.plan_active = plan;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async downloadInvoice(url: string) {
      this.download = true;
      const element = document.createElement("a");
      element.setAttribute("href", url);
      element.click();
      this.download = false;
    },
    getFormattedDate(date: number) {
      return this.$moment(date * 1000).format("MM/DD/YYYY");
    },
    async changePayment() {
      this.changeCard = true;

      if (this.changeCard) {
        this.loadings.loadingCardSetup = true;
        await this.loadStripeInfo();
        const elements = this.$stripe.elements();
        const cardElement = elements.create("card");
        this.cardElement = cardElement;
        this.loadings.loadingCardSetup = false;

        this.$nextTick(() => {
          cardElement.mount("#card-element");
        });
      }
    },
    async changePaymentCard(): Promise<void> {
      if (this.cardHolder) {
        this.loadings.loadingAddCard = true;
        const { setupIntent, error } = await this.$stripe.confirmCardSetup(
          this.stripe.client_secret,
          {
            payment_method: {
              card: this.cardElement,
              billing_details: { name: this.cardHolder }
            }
          }
        );

        if (error) {
          await this.$store.dispatch("alert/showError", error.message);
        } else {
          await this.addCard(setupIntent);
        }
        this.loadings.loadingAddCard = false;
      } else {
        await this.$store.dispatch("alert/showError", "Owner's name incomplete");
      }
    },
    async addCard(intent: any): Promise<void> {
      try {
        if (this.useAsDefault) {
          await this.$API
            .billing()
            .changeCard(
              Number(this.credentials.user.id),
              intent.payment_method
            );
        }
        await this.loadCardInfo();
        this.changeCard = false;
        await this.$store.dispatch(
          "alert/showSuccess",
          "The card has been successfully added!"
        );
      } catch (e) {
        console.log(e);
      }
    },
    async makeDefault(id: string): Promise<void> {
      try {
        await this.$API
          .billing()
          .changeCard(Number(this.credentials.user.id), id);
        await this.loadCardInfo();
        await this.$store.dispatch(
          "alert/showSuccess",
          "The card has been successfully updated!"
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    sortedOptions(plan: { name: string; options: any }, plans: any){
      if (plan.name === "ReportCare" || plan.name === "ReportCare (New Price)") {
        const options: Record<string, any> = {};
        for (const key in plans.options) {
          if(plan.options.includes(Number(key))){
            options[key] = plans.options[key];
          }
        }
        return options;
      } else {
        return plans.options;
      }
    }
  }
});
